<!--<template>-->
<!--  <div class="iframeModule">-->
<!--&lt;!&ndash;    <iframe id="iframe" name="ifrmname" frameborder="0" :src="srcs"></iframe>&ndash;&gt;-->
<!--    <WujieVue-->
<!--      width="100%"-->
<!--      height="100%"-->
<!--      name="module_ysjy"-->
<!--      :url="moduleUrl"-->
<!--      :sync="true"-->
<!--      :plugins="[-->
<!--        {-->
<!--          jsBeforeLoaders: [-->
<!--            {src: `../static/tv/charting_library/charting_library.min.js`},-->
<!--            {src: `../static/tv/datafeeds/udf/dist/bundle.js`},-->
<!--            {src: `../static/tv/datafeeds/udf/dist/polyfills.js`},-->
<!--            ]-->
<!--        }-->
<!--        ]"-->
<!--    />-->
<!--  </div>-->

<!--&lt;!&ndash;  <script src='./static/tv/charting_library/charting_library.min.js' ></script>&ndash;&gt;-->
<!--&lt;!&ndash;  <script src='./static/tv/datafeeds/udf/dist/bundle.js' ></script>&ndash;&gt;-->
<!--&lt;!&ndash;  <script src='./static/tv/datafeeds/udf/dist/polyfills.js' ></script>&ndash;&gt;-->
<!--  &lt;!&ndash;    <script src="./static/wow/wow.min.js" ></script>&ndash;&gt;-->
<!--  &lt;!&ndash;    <link rel="stylesheet" href="./static/wow/animate.css" >&ndash;&gt;-->
<!--</template>-->

<!--<script>-->
<!--import hostMap from "@/hostMap";-->
<!--import {mapState} from "vuex";-->


<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      userMes: null,-->
<!--      srcs: "",-->
<!--      newStr: new Date().getTime(),-->
<!--      moduleUrl: null,-->
<!--      urlQuery: ''-->
<!--    };-->
<!--  },-->
<!--  created() {-->
<!--    let that = this;-->
<!--    this.userMes = this.$store.state.common.sessionInfo;-->
<!--    that.srcs =-->
<!--      JSON.parse(localStorage.getItem("setHeaderText")).url +-->
<!--      `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${-->
<!--        that.userMes.sessionStr-->
<!--      }&tel=${that.userMes.tel}&userId=${-->
<!--        that.userMes.userId-->
<!--      }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${-->
<!--        this.newStr-->
<!--      }`;-->
<!--    if (that.srcs.indexOf("undefined") != -1) {-->
<!--      that.srcs = localStorage.getItem("iframeUrl");-->
<!--    } else {-->
<!--      localStorage.setItem("iframeUrl", that.srcs);-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--   this.urlQuery = this.$route.query-->
<!--   this.moduleUrl =  hostMap("//localhost:3002/") + '#'+ JSON.parse(localStorage.getItem("setHeaderText")).url-->
<!--  },-->
<!--  computed: {-->
<!--    ...mapState({-->
<!--      src: state => state.search.headerText.url-->
<!--    }),-->
<!--  },-->
<!--  watch: {-->
<!--    src(val) {-->
<!--      this.moduleUrl =  hostMap("//localhost:3002/") + '#'+ JSON.parse(localStorage.getItem("setHeaderText")).url-->
<!--    }-->
<!--  }-->
<!--};-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.iframeModule {-->
<!--  height: 100%;-->
<!--  width: 100%;-->
<!--}-->
<!--</style>-->

<template>
  <div class="iframeModule">
    <iframe id="iframe" name="ifrmname" frameborder="0" :src="srcs"></iframe>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  export default {
    data() {
      return {
        userMes: null,
        srcs: "",
        newStr: new Date().getTime(),
      };
    },
    computed: {
      ...mapGetters(["iframeStateGetter"]),
      ...mapState({
        src: state => state.search.headerText.url
      }),
      cUrl() {
        let newStr = new Date();
        return this.srcs + "?newDate=" + newStr;
      }
    },
    watch: {
      iframeStateGetter() {
        document.getElementById("iframe").src = "javascript:false";
      },
      src(val) {
        if (this.srcs.indexOf("undefined") != -1) {
          this.srcs = localStorage.getItem("iframeUrl");
        } else {
          this.srcs =
            JSON.parse(localStorage.getItem("setHeaderText")).url +
            `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
              this.userMes.sessionStr
            }&tel=${this.userMes.tel}&userId=${
              this.userMes.userId
            }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${
              this.newStr
            }`;
          localStorage.setItem("iframeUrl", this.srcs);
        }
      }
    },
    created() {
      let that = this;
      this.userMes = this.$store.state.common.sessionInfo;
      that.srcs =
        JSON.parse(localStorage.getItem("setHeaderText")).url +
        `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
          that.userMes.sessionStr
        }&tel=${that.userMes.tel}&userId=${
          that.userMes.userId
        }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${
          this.newStr
        }`;
      if (that.srcs.indexOf("undefined") != -1) {
        that.srcs = localStorage.getItem("iframeUrl");
      } else {
        localStorage.setItem("iframeUrl", that.srcs);
      }
    },
    mounted() {
      const OnMessage = (e) => {
        if(e.data === 'login') {
          // this.$message.warning('登录失效，请重新登录')
          setTimeout(()=>{
            this.$router.push('/login')
          },1500)

        }
      }
      if (window.addEventListener) {  // all browsers except IE before version 9
        window.addEventListener("message", OnMessage, false);
      } else {
        if (window.attachEvent) {   // IE before version 9
          window.attachEvent("onmessage", OnMessage);
        }
      }
    },
    destroyed() {
      removeEventListener('message')
    }
  };
</script>

<style lang="scss" scoped>
  .iframeModule {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      min-height: 100vh;
    }
  }
</style>

